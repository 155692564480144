/**
 * Helper to avoid child components to call parent events
 * @param {() => void} callback event to be called after stop the propagation
 * @param {boolean=false} force force event to propagate
 */
export function preventPropagation(callback, force = false) {
    return (e) => {
        if (e.currentTarget !== e.target && !force)
            return;
        e.stopPropagation();
        callback();
    };
}
