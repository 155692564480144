import { cva } from 'class-variance-authority';
export const tagRoot = cva('box-border select-none ::before:box-border ::after:box-border inline-flex flex-col flex-shrink-0 focus:outline-none transition-colors ease-out duration-75 group min-w-0', {
    variants: {
        variant: {
            outline: [
                'bg-tag border-tag text-tag-primary',
                'data-[state=on]:bg-tag-selected data-[state=on]:border-tag-selected data-[state=on]:text-tag-primary focus-visible:data-[state=on]:border-none',
                'disabled:!bg-transparent disabled:pointer-events-none disabled:!border-tag-disabled disabled:text-tag-disabled',
                'data-[editable]:border-disabled',
            ],
            filled: 'bg-component-secondary border-none',
            error: 'bg-error-secondary border border-error-secondary',
            warning: 'bg-warning-secondary border border-warning-secondary',
        },
        interactive: {
            true: 'cursor-pointer focus-visible:ring-tag-focus',
        },
        size: {
            xs: 'py-tag-xs-y px-tag-xs-x rounded-tag-xs gap-tag-xs-y min-h-tag-xs',
            sm: 'py-tag-sm-y px-tag-sm-x rounded-tag-sm gap-tag-sm-y min-h-tag-sm',
            md: 'py-tag-md-y px-tag-md-x rounded-tag-md gap-tag-md-y',
            lg: 'py-tag-lg-y px-tag-lg-x rounded-tag-lg gap-tag-lg-y',
            xl: 'py-tag-xl-y px-tag-xl-x rounded-tag-xl gap-tag-xl-y',
        },
    },
    compoundVariants: [
        {
            interactive: true,
            variant: 'outline',
            className: 'hover:bg-tag-hover active:bg-tag-press hover:border-tag-hover',
        },
        {
            interactive: true,
            variant: 'filled',
            className: 'hover:bg-component-secondary-hover active:bg-component-secondary-press',
        },
    ],
    defaultVariants: {
        size: 'md',
        variant: 'outline',
        interactive: false,
    },
});
export const tagRow = cva('flex w-full flex-1 flex-shrink-0 min-w-0', {
    variants: {
        size: {
            xs: 'gap-tag-xs-x',
            sm: 'gap-tag-sm-x',
            md: 'gap-tag-md-x',
            lg: 'gap-tag-lg-x',
            xl: 'gap-tag-xl-x',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export const tagLabel = cva('text-start flex-1 text-tag-primary group-disabled:text-tag-disabled align-center min-w-0', {
    variants: {
        size: {
            xs: 'tag-xs',
            sm: 'tag-sm',
            md: 'tag-md',
            lg: 'tag-lg',
            xl: 'tag-xl',
        },
        wrapText: {
            true: '',
            false: 'whitespace-nowrap overflow-hidden overflow-ellipsis',
        },
        variant: {
            error: 'text-onerror-secondary',
            warning: 'text-onwarning-secondary',
            filled: '',
            outline: '',
        },
    },
    defaultVariants: {
        size: 'md',
        wrapText: true,
    },
});
export const icon = cva('icon-tag-primary group-disabled:icon-tag-disabled object-center self-center shrink-0', {
    variants: {
        size: {
            xs: 'w-tag-icon-xs h-tag-icon-xs',
            sm: 'w-tag-icon-sm h-tag-icon-sm',
            md: 'w-tag-icon-md h-tag-icon-md',
            lg: 'w-tag-icon-lg h-tag-icon-lg',
            xl: 'w-tag-icon-xl h-tag-icon-xl',
        },
        variant: {
            error: 'icon-onerror-secondary',
            warning: 'icon-onwarning-secondary',
            filled: '',
            outline: '',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export const tagImage = cva('object-contain w-full h-auto group-disabled:grayscale group-disabled:opacity-40 max-h-[400px]');
export const closeButton = cva([
    'icon-tag-primary hover:bg-component-tertiary-hover active:bg-component-tertiary-press cursor-pointer outline-none focus-visible:ring-tag-focus',
    'group-disabled:icon-tag-disabled shrink-0',
], {
    variants: {
        size: {
            xs: 'p-12 rounded-tag-xs',
            sm: 'p-12 rounded-tag-sm',
            md: 'p-25 rounded-tag-md',
            lg: 'p-25 rounded-tag-lg',
            xl: 'p-25 rounded-tag-xl',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
const controlContainer = cva('grid content-center shrink-0 justify-center', {
    variants: {
        size: {
            xs: 'w-tag-icon-xs h-tag-icon-xs',
            sm: 'w-tag-icon-sm h-tag-icon-sm',
            md: 'w-tag-icon-md h-tag-icon-md',
            lg: 'w-tag-icon-lg h-tag-icon-lg',
            xl: 'w-tag-icon-xl h-tag-icon-xl',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
const radio = cva([
    'w-control-container-xxxs h-control-container-xxxs pointer-events-auto focus-visible:outline-control-focus focus-visible:outline-control-state-layer-focus focus-visible:outline-2 focus-visible:outline-offset-[5px] flex justify-center items-center border-component-secondary rounded-control-radio-button-container border-control group-data-[state=on]:bg-control-container group-data-[state=on]:icon-control-primary focus-visible:outline',
    'group-disabled:border-disabled group-disabled:group-data-[state=off]:border-control-container-disabled group-disabled:data-[state=on]:bg-control-container-disabled group-disabled:group-data-[state=on]:border-none shrink-0',
]);
const radioIndicator = cva("flex justify-center items-center w-full h-full relative after:content-[''] after:block after:w-[6.67px] after:h-[6.67px] after:rounded-full after:bg-current icon-control-primary");
const checkbox = cva('w-control-container-xxxs h-control-container-xxxs pointer-events-auto focus-visible:outline-control-state-layer-focus focus-visible:outline-2 focus-visible:outline-offset-[5px] flex justify-center items-center border-component-secondary rounded-control-checkbox-container border-control group-data-[state=on]:bg-control-container group-data-[state=on]:icon-control-primary focus-visible:outline shrink-0');
const checkboxIndicator = cva('flex justify-center items-center max-w-[12px] max-h-[12px]');
const input = cva('w-full h-full outline-none bg-transparent', {
    variants: {
        size: {
            xs: 'text-field-text-xs placeholder:text-field-text-xs text-text-field-label-text placeholder:text-text-field-placeholder',
            sm: 'text-field-text-sm placeholder:text-field-text-sm text-text-field-label-text placeholder:text-text-field-placeholder',
            md: 'text-field-text-md placeholder:text-field-text-md text-text-field-label-text placeholder:text-text-field-placeholder',
            lg: 'text-field-text-lg placeholder:text-field-text-lg text-text-field-label-text placeholder:text-text-field-placeholder',
            xl: 'text-field-text-xl placeholder:text-field-text-xl text-text-field-label-text placeholder:text-text-field-placeholder',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export const tagStyles = {
    tagRoot,
    tagLabel,
    icon,
    tagImage,
    tagRow,
    closeButton,
    input,
    controlContainer,
    radio: {
        root: radio,
        indicator: radioIndicator,
    },
    checkbox: {
        root: checkbox,
        indicator: checkboxIndicator,
    },
};
