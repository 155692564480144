import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { envConfig } from '#app/config';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function absoluteUrl(path: string) {
  return `${envConfig.appUrl}${path}`;
}
