import { AlertTriangleFilled, Comment2DotsFilled, QuestionFilled } from '@zealy/icons';

export const TAG_TYPES = ['question', 'feedback', 'problem'] as const;

export const TAGS_ICONS = {
  problem: <AlertTriangleFilled />,
  question: <QuestionFilled />,
  feedback: <Comment2DotsFilled />,
} as const;

export const CHROME_CONSOLE_GUIDE_URL =
  'https://zealy.notion.site/zealy/How-to-report-bugs-cdf181deb6ab45f1bf57487d34106cca';
