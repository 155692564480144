import { AlertTriangleLine, CheckLine } from '@zealy/icons';
export function getComponentState({ isDisabled, isInvalid, isSuccess, }) {
    if (isDisabled) {
        return 'disabled';
    }
    if (isInvalid) {
        return 'error';
    }
    if (isSuccess) {
        return 'success';
    }
    return 'default';
}
export const FeedbackIcon = {
    error: AlertTriangleLine,
    success: CheckLine,
    default: undefined,
    disabled: undefined,
};
