import type { $Fetch, FetchOptions } from 'ofetch';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

export interface IResponseMap {
  blob: Blob;
  text: string;
  arrayBuffer: ArrayBuffer;
  stream: ReadableStream<Uint8Array>;
}

export type ApiType = <T = any, R extends ResponseType = 'json'>(
  request: FetchRequest,
  options?: FetchOptions<R>,
) => Promise<MappedType<R, T>>;

export type ResponseType = keyof IResponseMap | 'json';

export type RequestInfo = globalThis.RequestInfo;
export type FetchRequest = RequestInfo;

export type Method = 'get' | 'post' | 'put' | 'patch' | 'delete';

export type MappedType<R extends ResponseType, JsonType = any> = R extends keyof IResponseMap
  ? IResponseMap[R]
  : JsonType;

export const getMe = () => {
  let metadata: string | undefined;

  if (process.env.NEXT_RUNTIME) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const cookieStore = require('next/headers').cookies();
    metadata = cookieStore.get('user_metadata')?.value;
  } else {
    metadata = Cookies.get('user_metadata');
  }

  if (!metadata) {
    // should never happen
    return undefined;
  }

  const decrypted = jwt.decode(metadata) as {
    userId: string;
  };

  return decrypted?.userId;
};

export const forwardedCookie = () => {
  if (process.env.NEXT_RUNTIME) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const cookieStore = require('next/headers').cookies();
    let cookies = '';
    const access_token = cookieStore.get('access_token')?.value;
    const partial_access_token = cookieStore.get('partial_access_token')?.value;
    const user_metadata = cookieStore.get('user_metadata')?.value;
    const partial_user_metadata = cookieStore.get('partial_user_metadata')?.value;
    if (access_token) {
      cookies = `access_token=${access_token};`;
    }
    if (partial_access_token) {
      cookies = `${cookies}partial_access_token=${partial_access_token};`;
    }
    if (user_metadata) {
      cookies = `${cookies}user_metadata=${user_metadata};`;
    }
    if (partial_user_metadata) {
      cookies = `${cookies}partial_user_metadata=${partial_user_metadata};`;
    }
    return cookies;
  }
};

// HTTP client factory
export function clientFactory<APIMethodHandlers>(__api: ApiType, $fetch: $Fetch) {
  const cache = new Map();

  const _api = new Proxy(__api, {
    apply(target, thisArg, args: Parameters<typeof __api>) {
      return __api(...args);
    },
    get(_, method) {
      const targetMethod = method as unknown as Method;
      if (!cache.has(targetMethod)) {
        cache.set(targetMethod, function api<
          T = any,
          R extends ResponseType = 'json',
        >(request: FetchRequest, options?: FetchOptions<R>): Promise<MappedType<R, T>> {
          return $fetch<T, R>(request, {
            ...options,
            method: targetMethod.toUpperCase(),
          }) as Promise<MappedType<R, T>>;
        });
      }
      return cache.get(targetMethod);
    },
  }) as unknown as APIMethodHandlers;

  return _api;
}
