import { useMutation } from '@tanstack/react-query';

import { getSubdomain } from '@zealy/utils';

import { createSupportTicket } from '../support.service';

/**
 * Hook for creating an intercom support ticket
 * @example ```
 * const { mutate, isLoading } = useCreateSupportTicket();
 * mutate({ email: 'user@email.com', description: 'my issue is...', files: ['https://image.com'] });
 * ```
 */
export const useCreateSupportTicket = () => {
  const subdomain = getSubdomain();
  return useMutation({
    mutationFn: (body: Parameters<typeof createSupportTicket>[1]) =>
      createSupportTicket(subdomain, body),
  });
};
