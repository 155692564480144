import { z } from 'zod';

export const defaultSupportModalFormSchema = z.object({
  files: z.array(z.string()),
  description: z.string().min(20),
  email: z.string().email(),
  communityId: z.string().optional(),
});

const problemSupportModalFormSchema = defaultSupportModalFormSchema.extend({
  files: z.array(z.string()).nonempty(),
});

export const supportFormSchema = {
  question: defaultSupportModalFormSchema,
  feedback: defaultSupportModalFormSchema,
  problem: problemSupportModalFormSchema,
} as const;
