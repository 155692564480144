'use client';
import { createContext } from '../../utils/component.context';
export const [TagContextProvider, useTagContext] = createContext({
    name: 'TagContext',
    strict: true,
    hookName: 'useTagContext',
    providerName: 'TagProvider',
    defaultValue: {
        size: 'md',
        tagType: 'default',
        disabled: false,
    },
});
