import { z } from 'zod';

import { isServerSide } from '#utils/ssr';

const config = {
  appEnv: process.env.NEXT_PUBLIC_APP_ENV ?? 'development',
  env: process.env.NODE_ENV ?? 'development',
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  apiV2Url: process.env.NEXT_PUBLIC_API_V2_URL,
  appUrl: process.env.NEXT_PUBLIC_APP_URL,
  ai: {
    key: process.env.AI_API_KEY,
  },
  security: {
    jwtPrivateKey: process.env.JWT_PRIVATE_KEY,
  },
  walletconnect: {
    projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
  },
  segment: {
    key: process.env.NEXT_PUBLIC_SEGMENT_KEY,
  },
  infura: {
    providerApiKey: process.env.NEXT_PUBLIC_INFURA_PROVIDER_API_KEY,
  },
  intercom: {
    websiteId: process.env.NEXT_PUBLIC_INTERCOM_WEBSITE_ID ?? 'nketzd4e',
  },
  flagsmith: {
    key: process.env.NEXT_PUBLIC_ZEALY_FLAGSMITH_KEY,
  },
  mixpanel: {
    key: process.env.NEXT_PUBLIC_MIXPANEL_KEY,
  },
  google: {
    recaptchSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
    analyticsTrackingId: 'G-XK8QB045BG',
    gtmId: 'GTM-PLFFS3ZD',
  },
  datadog: {
    appId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    sessionSampleRate: process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE,
    sessionReplaySampleRate: process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_SAMPLE_RATE,
  },
  typeform: {
    enterpriseInquiryFormUrl:
      process.env.NEXT_PUBLIC_TYPEFORM_ENTERPRISE_INQUIRY_FORM_URL ??
      'https://form.typeform.com/to/gJyyUPcL',
  },
  maintenanceMode: process.env.IS_MAINTENANCE_MODE,
};

const schema = z.object({
  appEnv: z.string(),
  env: z.string(),
  apiUrl: z.string(),
  apiV2Url: z.string(),
  appUrl: z.string(),
  ai: z.object({
    key: isServerSide() ? z.string() : z.string().optional(),
  }),
  security: z.object({
    jwtPrivateKey: isServerSide() ? z.string() : z.string().optional(),
  }),
  walletconnect: z.object({
    projectId: z.string(),
  }),
  segment: z.object({
    key: z.string(),
  }),
  infura: z.object({
    providerApiKey: z.string(),
  }),
  intercom: z.object({
    websiteId: z.string(),
  }),
  flagsmith: z.object({
    key: z.string(),
  }),
  mixpanel: z.object({
    key: z.string(),
  }),
  google: z.object({
    recaptchSiteKey: z.string(),
    analyticsTrackingId: z.string(),
    gtmId: z.string(),
  }),
  datadog: z.object({
    appId: z.string(),
    clientToken: z.string(),
    sessionSampleRate: z.number().optional(),
    sessionReplaySampleRate: z.number().optional(),
  }),
  typeform: z.object({
    enterpriseInquiryFormUrl: z.string(),
  }),
  maintenanceMode: isServerSide() ? z.string() : z.string().optional(),
});

const result = schema.safeParse(config);

if (!result.success) {
  const keys = result.error.errors.map(({ path }) => path.join('.'));
  throw new Error(`Missing env keys: ${JSON.stringify(keys, null, 2)}`);
}

const envConfig: z.infer<typeof schema> = result.data;

export { envConfig };
