import type { ClientInferRequest, contract } from '@zealy/contracts';

import { apiV2 } from '../services';

export const createSupportTicket = async (
  subdomain: string,
  body: ClientInferRequest<typeof contract.support.create>['body'],
) => {
  const res = await apiV2.support.create({
    params: {
      subdomain,
    },
    body,
  });

  if (res.status !== 200) {
    // handle error here
    throw new Error('createSupportTicket failed');
  }
};
