import { cva } from 'class-variance-authority';
const formControl = cva('flex flex-col w-full group', {
    variants: {
        size: {
            sm: 'gap-text-field-sm-y',
            md: 'gap-text-field-md-y',
            lg: 'gap-text-field-lg-y',
            xl: 'gap-text-field-xl-y',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
const hint = cva('', {
    variants: {
        state: {
            default: 'text-text-field-hint',
            error: 'text-text-field-hint-error',
            success: 'text-text-field-hint',
            disabled: 'text-text-field-disabled',
        },
        size: {
            sm: 'text-field-hint-sm',
            md: 'text-field-hint-md',
            lg: 'text-field-hint-lg',
            xl: 'text-field-hint-xl',
        },
    },
    defaultVariants: {
        state: 'default',
        size: 'md',
    },
});
const optional = cva('', {
    variants: {
        state: {
            default: 'text-text-field-optional',
            error: 'text-text-field-optional',
            success: 'text-text-field-optional',
            disabled: 'text-text-field-disabled',
        },
        size: {
            sm: 'text-field-hint-sm',
            md: 'text-field-hint-md',
            lg: 'text-field-hint-lg',
            xl: 'text-field-hint-xl',
        },
    },
    defaultVariants: {
        state: 'default',
        size: 'md',
    },
});
export const label = cva('block', {
    variants: {
        disabled: {
            true: 'text-text-field-disabled',
            false: 'text-text-field-label-text',
        },
        size: {
            sm: 'text-field-label-sm',
            md: 'text-field-label-md',
            lg: 'text-field-label-lg',
            xl: 'text-field-label-xl',
        },
    },
    defaultVariants: {
        disabled: false,
        size: 'md',
    },
});
export const formControlStyles = {
    formControl,
    hint,
    optional,
    label,
};
