'use client';

import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslations } from 'next-intl';

import type { FileCardProps } from '@zealy/design-system';
import type { User } from '@zealy/queries';
import { Button, FILE_UPLOAD_ERRORS, Link, Tag, TextArea, TextField } from '@zealy/design-system';
import { ArrowRightLine, EmailLine, FileUploadLine } from '@zealy/icons';
import { useAuthenticatedUser, useCommunity } from '@zealy/queries';

import { FileUploader } from '#components/FileUpload';
import { Modal } from '#components/Modal';
import { useToast } from '#components/Toaster/useToast';
import { useDidMount } from '#hooks/useDidMount';
import { useQueryParams } from '#hooks/useQueryParams';

import type { SupportModalForm, TagType } from './SupportModal.types';
import { useCreateSupportTicket } from '../../../queries/src/support/mutations/useCreateSupportTicket';
import { CHROME_CONSOLE_GUIDE_URL, TAG_TYPES, TAGS_ICONS } from './SupportModal.constants';
import { supportFormSchema } from './SupportModal.utils';

export const SupportModal = () => {
  const user = useAuthenticatedUser<User>();
  const [items, setItems] = useState<FileCardProps[]>([]);
  const didMount = useDidMount();
  const inputRef = useRef<HTMLInputElement>(null);

  const community = useCommunity();

  const t = useTranslations('support-modal');

  const { queryParams, setQueryParams } = useQueryParams();

  const createSupportTicket = useCreateSupportTicket();

  const toast = useToast();

  const supportRequest = queryParams.get('support-modal') as TagType;
  const emailQueryParams = queryParams.get('email');
  const communityIdParams = queryParams.get('communityId');

  const communityId = communityIdParams ?? community.data?.id;
  const accounts = user.data && 'accounts' in user.data ? user.data.accounts : [];
  const email =
    emailQueryParams ??
    accounts?.find(account => account.accountType === 'email')?.authentification;
  const isOpen = !!supportRequest && TAG_TYPES.includes(supportRequest);

  const validationSchema = supportFormSchema[supportRequest];

  const { handleSubmit, setValue, formState, register } = useForm<SupportModalForm>({
    defaultValues: {
      files: [],
      description: '',
      email,
      communityId: communityId ?? undefined,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: zodResolver(validationSchema),
  });

  if (!didMount || !supportRequest) return null;

  const onFileUpload = (file: FileCardProps) => {
    setItems(prevItems => {
      const newItems = prevItems.map(item => (item.file.name === file.file.name ? file : item));

      const uploadedFiles = newItems.reduce((acc, { url }) => {
        if (!url || typeof url !== 'string') return acc;
        return [...acc, url];
      }, [] as string[]);
      setValue('files', uploadedFiles);
      return newItems;
    });
  };

  const closeModal = () => setQueryParams({ 'support-modal': undefined });

  const onSubmit = (input: SupportModalForm) => {
    createSupportTicket.mutate(
      { ...input, type: supportRequest },
      {
        onSuccess: () => {
          closeModal();
          toast(t('notification.success'));
        },
        onError: () => {
          toast(t('notification.failure'));
        },
      },
    );
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    // Filter out files that are above the size limit
    const files = Array.from(e.target.files);

    const fileData = files.map(file => {
      const error = file.size > 50000000 ? FILE_UPLOAD_ERRORS.FILE_TOO_BIG : undefined;
      return {
        file,
        error,
      };
    });

    setItems(prev => [...prev, ...fileData]);
  };

  const onRemove = (file: File) => {
    setItems(items.filter(item => item.file.name !== file.name));
  };

  if (!supportRequest) return null;

  return (
    <Modal
      open={isOpen}
      className="flex flex-col sm:max-w-screen-sm"
      onOpenChange={() => closeModal()}
    >
      <div className="p-300 border-b flex-1">
        <h2 className="headings-title-t2 mb-50">{t(`${supportRequest}.title`)}</h2>
        <p className="body-paragraph-md text-secondary">{t(`${supportRequest}.description`)}</p>
      </div>

      <div className="p-300 flex flex-col gap-200 overflow-y-auto max-h-[70vh]">
        <div className="flex gap-100 flex-wrap">
          {TAG_TYPES.map(tagType => (
            <Tag
              size="sm"
              key={tagType}
              leftIcon={TAGS_ICONS[tagType]}
              onClick={() => setQueryParams({ 'support-modal': tagType })}
              selected={supportRequest === tagType}
            >
              {t(`${tagType}.tag`)}
            </Tag>
          ))}
        </div>

        {!email && (
          <TextField
            label="Email"
            type="email"
            leftIcon={<EmailLine />}
            placeholder="kenny@zealy.io"
            hint={formState.errors.email?.message ?? 'So we can get back to you'}
            isInvalid={!!formState.errors.email}
            {...register('email')}
          />
        )}

        <TextArea
          placeholder={t(`${supportRequest}.placeholder`)}
          isInvalid={!!formState.errors.description}
          hint={formState.errors.description?.message}
          {...register('description')}
        />

        <div className="flex flex-col items-start gap-50">
          <Button
            variant="ghost"
            size="sm"
            leftIcon={<FileUploadLine />}
            mutedText
            className={'self-start'}
            onClick={() => inputRef.current?.click()}
            color={formState.errors.files?.message ? 'destructive' : 'default'}
          >
            {t(`${supportRequest}.attachments`)}
          </Button>
          <Link
            className="pl-button-sm"
            rightIcon={<ArrowRightLine />}
            size="sm"
            href={CHROME_CONSOLE_GUIDE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('console')}
          </Link>
        </div>

        <input type="file" className="hidden" ref={inputRef} onChange={onFileChange} multiple />

        {items.map(({ file, error }) => (
          <FileUploader
            key={file.name}
            {...{ file, items, error, onRemove, onItemChanged: onFileUpload }}
          />
        ))}
      </div>

      <div className="p-300 border-t flex flex-row justify-between items-center">
        <span className="text-secondary body-paragraph-md">
          {t(`${supportRequest}.helperText`)}
        </span>
        <Button
          color="cta"
          loading={createSupportTicket.isPending}
          className="w-min"
          size={'md'}
          onClick={handleSubmit(onSubmit)}
        >
          {t(`${supportRequest}.cta`)}
        </Button>
      </div>
    </Modal>
  );
};
